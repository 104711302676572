<template>
  <v-dialog
    :value="showForm"
    @input="(v) => v || HIDE_FORM()"
    max-width="600px"
    scrollable
    persistent
  >
    <v-card>
      <!-- TITLE -->
      <v-card-title class="primary">
        <span class="headline">{{
          editMode ? " تعديل القسم" : " اضافة قسم فرعى جديد"
        }}</span>
      </v-card-title>

      <!-- INPUTS -->
      <v-card-text class="pt-5 pb-0">
        <v-container>
          <v-progress-linear
            v-if="formLoading"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <div v-else>
            <v-autocomplete
              dense
              outlined
              hide-details
              clearable
              label="التصنيف الرئيسي"
              prepend-icon="mdi-sitemap"
              class="mb-5"
              item-text="name"
              item-value="id"
              v-model="form.category_id"
              :error-messages="errors.category_id"
              :items="sections"
            ></v-autocomplete>
            <v-text-field
              dense
              outlined
              label="الاسم بالعربية"
              v-model="form.name_ar"
              :error-messages="errors.name_ar"
            ></v-text-field>
            <v-text-field
              dense
              outlined
              label="الاسم بالانجليزية"
              v-model="form.name_en"
              :error-messages="errors.name_en"
            ></v-text-field>

            <v-text-field
              dense
              outlined
              label="الترتيب"
              v-model="form.order"
              :error-messages="errors.order"
            ></v-text-field>
            
            <v-file-input
              dense
              outlined
              label="الصورة"
              prepend-icon="mdi-image"
              accept="image/*"
              v-model="form.icon"
              :error-messages="errors.icon"
            ></v-file-input>
            <p class="error_class">*accept PNG SIZES 250*250 AND LESS</p>

          </div>
        </v-container>
      </v-card-text>

      <!-- ACTIONS -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="formLoading"
          color="secondary"
          elevation="1"
          dark
          @click="editMode ? update(form) : create(form)"
          >حفظ</v-btn
        >
        <v-btn @click="HIDE_FORM();"> 
          إلغاء
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState, mapMutations , mapActions} from "vuex";

export default {
  name: "record-form",

  data: function() {
    return {
      form: {},
      errors: {},
      formLoading: false,
      sections: [],
    };
  },
  created(){
    this.fetchSections();
  },
  computed: {
    ...mapState("subcategories", {
      editMode: state => state.editMode,
      showForm: state => state.showForm,
      formRecord: state => {
        return {
          id: state.formRecord.id,
          name_ar: state.formRecord.name_ar,
          name_en: state.formRecord.name_en,
          order: state.formRecord.order,
          section_id: state.formRecord.section_id,
          // icon: state.formRecord.icon
        };
      }
    })
  },

  watch: {
    formRecord(form) {
      this.form = form;
    }
  },

  methods: {
    ...mapMutations("subcategories", ["HIDE_FORM"]),
    ...mapActions("subcategories", ["fetchRecords"]),
    fetchSections(){
      this.axios.get("/admin/subcategories/categories_dropdown").then((response) =>{
        this.sections = response.data;
      });
    },
    create(form) {
      this.formLoading = true;
      // form.section_id = this.item.id;
      // // console.log(form.section_id);
      this.$store
        .dispatch("subcategories/create", { form })
        .then(response => {
          this.$bus.$emit("showSnackbar", {
            text: "تمت اضافة التصنيف بنجاح",
            color: "success"
          });
          this.HIDE_FORM();
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
         for (let key in error.response.data.errors) {
            this.$bus.$emit("showSnackbar", {
              text: error.response.data.errors[key][0],
              color: "error",
            });
          }
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords()
        });
    },

    update(form) {
      this.formLoading = true;
      // console.log({ form });
      this.$store
        .dispatch("subcategories/update", { form })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: "Data updated",
            color: "success"
          });
          this.HIDE_FORM();
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        for (let key in error.response.data.errors) {
            this.$bus.$emit("showSnackbar", {
              text: error.response.data.errors[key][0],
              color: "error",
            });
          }
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords()
        });
    }
  }
};
</script>
