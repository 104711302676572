<template>
  <div class="filter-box py-5 px-4 mb-6">
    <h3 class="primary--text mb-3">
      <v-icon class="ml-1">mdi-filter</v-icon>التصنيفات والبحث 
    </h3>
    <v-row align="center">
      <!-- QUERY -->

      <!-- Q -->
      <v-col cols="12" md="6">
        <v-text-field
          rounded
          outlined
          solo
          hide-details
          clearable
          label="البحث بالاسم" 
          v-model="filters.search"
        ></v-text-field>
        <v-autocomplete
          rounded
          outlined
          hide-details
          clearable
          solo
          label="القسم"
          class="mb-5 my-2"
          v-model="section"
          :items="sections"
          @change="fetchCategories(section)"
        ></v-autocomplete>
        <v-autocomplete
          rounded
          outlined
          hide-details
          clearable
          solo
          label="التصنيف"
          class="mb-5 my-2"
          v-model="filters.category_id"
          :items="categories"
          @click="handelFirstClick"
        ></v-autocomplete>
      </v-col>

      <!-- SEARCH BUTTON -->
      <v-col class="d-flex" cols="6">
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          @click="$router.replace({ query: { ...filters, page: 1 } })"
          large
          color="primary"
          >بحث</v-btn
        >
          
        <v-btn
          class="mr-2"
          @click="$router.replace({ query: { page: 1 , active : 0 , search:'' , category_id : '' } })"
          large
          color="primary"
          >عرض الكل
        </v-btn>
        <v-btn
          class="mr-2"
          @click="$router.replace({ query: { page: 1 , active:1} })"
          large
          color="primary"
        >
          عرض النشط
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { mapState, mapMutations, mapActions } from "vuex";
import swal from "sweetalert";

export default {
  name: "filters-box",

  data: function () {
    return {
      categoriesLoading: false,
      section: "",
      category: "",
      filters: {
        search: null,
      },
    };
  },

  created() {
    this.fetchSections();
  },

  computed: {
    ...mapState({
      sections: (state) => state.filters.sections,
      categories: (state) => state.filters.categories
      
      }),
  },

  methods: {
    emptyCategories() {
      this.subcategories.length=0;
    },
    ...mapMutations("subcategories", ["SET_FILTERS"]),
    ...mapActions("filters", ["fetchSections" , "fetchCategories"]),
    ...mapActions("subcategories", ["fetchRecords"]),
      handelFirstClick() {
        // this.categories.length=0;
      if (this.section == "") {
        swal("لابد من اختيار القسم اولا ثم التصنيف ثم التصنيف الفرعي");
      }
    },
  },
};
</script>
